$ = jQuery;
$(function(){
	preloader();
	/* header */
	headerMobile();
	headerSearch();
	ajaxCountWishlist();
	fixInputStyle();
	translateSelect();


	/* main-js */
	popupVideo();
	lockPopup();

	/* Front page */
	hideShowHeader();
	// hBannerSlider();
	hBannersSlider();
	hVideo();
	isScroll();
	HeaderFrontPage();
	removeInputAutoComplete();

	/* post page */
	scrollPostShare();


	/* home page */
	ajaxLoadPost();


	/* product page */
	popupProductPage();
	addToCartValidation();
	makeSelectRequired();
	notClickableProductImage();
	changeBtnColorOnValidation();

	// popupVideoProduct();
	// product_video();
	productPopupSlider();
	preventClicksThroughOverlay();

	autocompleteFix();


	/* cart-checkout page */
	incDecQuantity();
	cartComment();


	/* archive product */
	archiveProductCount();
	archiveProductSliders();
	archiveProductPagination();
	archiveProductFilter();	

	/* SEO texts */
	showSEOtext();

	/* Test block */
	// removeAlertInIput();

	// slickvideo();
});

function preloader() { 
    $(window).load(function () {
        $('#preloader').fadeOut('slow', function () { $(this).remove(); });
    });
}
function fixInputStyle() {
	$('.country-select option:first-child').text('Країна *').addClass('label').css('font-weight', '200');
	$('.country-select').after('<i class="fa fa-chevron-down"></i>');
	$('.country-select').css('opacity', '0.6');
	if(navigator.userAgent.indexOf("Chrome") != -1 )
	{
		$('.wpcf7-form-control-wrap input').css('opacity', '0.7');
	}
	$(".country-select").on({
		"change": function() {
			$(this).blur();
		},
		"blur": function() {
			$('.wpcf7-form-control-wrap .fa').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
		},
		"click": function() {
			$('.wpcf7-form-control-wrap .fa').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');			
			if($('.country-select option:selected').hasClass('label')){
				$('.country-select').css('opacity', '0.6');
			} else {
				$('.country-select').css('opacity', '1');
			}
		}
	});	 
	$(".wpcf7-form-control-wrap input").on("click", function() {
		$('.wpcf7-form-control-wrap input:focus').css('opacity', '1');
	});	
}
/* ----------------- start country select ----------------- */

function translateSelect() {
	lang = $('html')[0].lang;
	// alert(lang);

	$('html[lang="uk"] .wpcf7 .country-select option:first-child').text('Країна *');
	$('html[lang="ru-RU"] .wpcf7 .country-select option:first-child').text('Страна *');
	$('html[lang="ro-RO"] .wpcf7 .country-select option:first-child').text('Tara *');
	$('html[lang="en-US"] .wpcf7 .country-select option:first-child').text('Country *');
	$('html[lang="uk"] label[for="pa_color"]').text('Колір');
	$('html[lang="ru-RU"] label[for="pa_color"]').text('Цвет');
	$('html[lang="ro-RO"] label[for="pa_color"]').text('Culoare');
}

/* ----------------- end country select ----------------- */

/* ----------------- start header ----------------- */

function headerMobile(){
	$('.site-header-info-burger span, .menu-overlay, .right-menu .close-menu').on('click', function(e) {
		$('.site-header').toggleClass('open-mob-menu');
		$('.site-header-info-burger').toggleClass('open-mob-menu');
		$('.right-menu').fadeToggle().toggleClass('menu-show menu-hide');
		$('body').toggleClass('has-open-modal').toggleClass('offset-scrollbar');
	});	
	$(".site-header-info-switcher select").after('<i class="fas fa-chevron-down"></i>');
	$(".site-header-info-switcher select").on({
		"change": function() {
			$(this).blur();
		},
		"blur": function() {
			$('.site-header-info-switcher').toggleClass('opened');
			$(".site-header-info-switcher .fas").toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
		},
		"click": function() {
			$('.site-header-info-switcher').toggleClass('opened');
			$(".site-header-info-switcher .fas").toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');			
		}
	}); 
	$('#content').on('click', function(){
		if($('.site-header-info-switcher').hasClass('opened')){
			$('.site-header-info-switcher').removeClass('opened');
			$(".site-header-info-switcher .fas").removeClass('fa-chevron-up').addClass('fa-chevron-down');
		}			
	});
}

function headerSearch(){
	var searchIcon = $('.search-icon');
	var searchForm = $('.search-form');

	searchIcon.on('click', function(){
		searchForm.toggleClass('active');
		$('header').toggleClass('open-search');
		if(searchForm.hasClass('active')){
			$('#keyword').focus();
		}
	})

	$(window).on('click', function(e) {
		if(!$(e.target).closest('.search-form, .search-icon').length){
			searchForm.removeClass('active');
			$('header').removeClass('open-search');
		}
	});
}


function ajaxCountWishlist(){
	$(document).on( 'added_to_wishlist removed_from_wishlist', function(){
		var counter = $('.count-prod-wishlist');

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			data: {
				action: 'yith_wcwl_update_wishlist_count'
			},
			dataType: 'json',
			success: function( data ){
				counter.html( data.count );
			},
			beforeSend: function(){
				//counter.block();
			},
			complete: function(){
				//counter.unblock();
			}
		})
	} )
}
/* ----------------- end header ----------------- */




/* ----------------- start autocomplete ----------------- */

function autocompleteFix() {
	$('input').on('click', function() {
		$(this).attr('autocomplete', 'false');
	});
}

/* ----------------- end autocomplete ----------------- */

/* ----------------- start main-js ----------------- */
function popupVideo(){
	$(".open-popup-video").modalVideo({
		youtube:{
			//controls: 0,
			autoplay: true,
			nocookie: true
		}
	});
}

/* ----------------- end main-js ----------------- */






/* ----------------- start Front page ----------------- */
function hBannerSlider(){
	var swiper = new Swiper('.front-banner-slide-container', {
		loop: true,
		effect: 'fade',
		autoplay: {
			delay: 3500,
		},
		navigation: {
			nextEl: '.front-banner-slider-btn-next',
			prevEl: '.front-banner-slider-btn-prev',
		},
	});
}
function hBannersSlider() {
	if (screen.width > 991) {
		$('.page-template-template-front-page #content').fullpage( {
			slidesNavigation: true,
			scrollingSpeed: 1000,
			/* In addition to the extension license you'll */
			/* need to acquire a fullPage.js license from https://goo.gl/5x9a22 */
			licenseKey: '11D35D58-43F94EA7-876C7B65-4E8CA8FD',
			parallax: true,
			parallaxKey: 'YmVsaWtzLmNvbV9ZT2xjR0Z5WVd4c1lYZz14ZEU=',  //see https://goo.gl/xkUmHS 
			parallaxOptions: {
				type: 'cover',
				percentage: 62,
				property: 'translate'
			},
			scrollOverflow: true,

		});

	}

}
/*
function hBannersSlider() {
	if (screen.width > 991) {
		new fullpage('.page-template-template-front-page #content', {
			slidesNavigation: true,
			scrollingSpeed: 1000,
			# In addition to the extension license you'll
			# need to acquire a fullPage.js license from https://goo.gl/5x9a22
			licenseKey: '11D35D58-43F94EA7-876C7B65-4E8CA8FD',
			parallax: true,
			parallaxKey: 'YmVsaWtzLmNvbV9ZT2xjR0Z5WVd4c1lYZz14ZEU=',  //see https://goo.gl/xkUmHS 
			parallaxOptions: {
				type: 'cover',
				percentage: 62,
				property: 'translate'
			},
			scrollOverflow: true,

		});
	}
}
*/
function hVideo(){
	var myVideo = document.getElementById('video');
	var $myVideo = $('#video'); 
	// $('.front-video').on('click', function(){
	// 	$(this).toggleClass('plaing');
	// 	if(myVideo.paused){
	// 		myVideo.play();
	// 	}
	// 	console.log('modal');
	// })

	// var myVideos = document.getElementById('div#video-mobile .front-banner-video-v');
	var $myVideos = $('div#video-mobile .front-banner-video-v'); 
		$('div#video-mobile .play').on('click', function(){
		$('div#video-mobile').toggleClass('active-video');

		$myVideos.get(0).play();
	
		console.log('modal');

		});
	$('.speaker').on('click', function(e){
		e.stopPropagation();
		$(this).toggleClass('mute');
		if( $myVideo.prop('muted') ) {
			$myVideo.prop('muted', false);
		} else {
			$myVideo.prop('muted', true);
		}
	})
}

function HeaderFrontPage(){
	if($("body").hasClass("page-template-template-front-page")){
		var header = $('.site-header');
		var lastScroll = 0;
		var currentScroll = 0;
		$(this).bind('mousewheel', function(e){
			var delta = e.originalEvent.deltaY;
			if(delta>0){
				header.css({
					'transform':'translate3d(0px, -155px, 0px)',
					'transition' : ' all .25s linear'
				});
				$('.site-header').addClass('top');
			} else if(delta<0){
				header.css({
					'transform':'translate3d(0px, 0px, 0px)',
					'transition' : ' all .25s linear'
				});
				$('.site-header').removeClass('top');
			}
		})
	}
}

function hideShowHeader(){
	var header = $('.site-header');
	var lastScroll = 0;
	var currentScroll = 0;
	$(window).on('scroll',function showHideHeader(){
		//if($(this).width() > 991){
			currentScroll = $(this).scrollTop();
			if(lastScroll < currentScroll){
				//scroll down
				if(currentScroll < 155){
					//header - position 'relative'
					header.css({
						'transform':'translate3d(0px, -' + currentScroll + 'px, 0px)',
						'transition' : 'none'
					});
				} else{
					//hide header
					header.css({
						'transform':'translate3d(0px, -155px, 0px)',
						'transition' : ' all .25s linear'
					});
				}
			} else{
				//scroll up - show header
				header.css({
					'transform':'translate3d(0px, 0px, 0px)',
					'transition' : ' all .25s linear'
				});	
			}
			lastScroll = currentScroll;
			//}
		}).trigger('scroll');
}

function isScroll(){
	$(window).scroll(function() {
		if($(this).scrollTop() >= 155) {
			$('.site-header').addClass('top');
		}
		else{
			$('.site-header').removeClass('top');
		}
	});
}
/* ----------------- end Front page ----------------- */


/* ----------------- start seo-text toggle --------------- */
function showSEOtext(){
	let fp_active = false,
	elHeight = $('.seo-block-content .content-spoiler').prop('scrollHeight'); /* get element real height */
	if ( $( 'html' ).hasClass( 'fp-enabled' ) ) {
		fp_active = true;
	};
	function handler1() {
		$(this).toggleClass('closed opened');
		$(this).prev('.content-spoiler').animate({height: elHeight}, 400, function(){if (fp_active) { $.fn.fullpage.reBuild() } } );
		$(this).one("click", handler2);
	}

	function handler2() {
		$(this).toggleClass('opened closed');
		$(this).prev('.content-spoiler').animate({height: 300}, 400, function(){if (fp_active) { $.fn.fullpage.reBuild() } } );
		$(this).one("click", handler1);
	}
	$('.seo-block-content .show-more').one("click", handler1);



	// $( document ).ready(function() {
		// 	$('.seo-text .content-spoiler' ).css( { 'height': '300px', 'overflow-y': 'hidden' } );
		// 	$( '#section5' ).addClass( 'fp-auto-height-responsive' );

		// 	var el = $('.seo-text .content-spoiler');
		// 	var elHeight;
		// 	elHeight = el.prop('scrollHeight');

		// 	$('.seo-text .show-more').on('click',function(){
			// 	$.fn.fullpage_api.reBuild();
			// 	$.fn.fullpage.setMouseWheelScrolling(false);
			
			// if( $(this).hasClass('active') ){
				// 	$( this ).empty().text( 'Показати більше' );
				// 	curHeight = el.prop('scrollHeight');
				// 	el.height(curHeight).animate({height: 300}, 1000);
				// } else{
					// 	$( this ).empty().text( 'Сховати текст' );
					// 	curHeight = el.height();
					// 	autoHeight = el.css('height', 'auto').height();
					// 	el.height(curHeight).animate({height: elHeight}, 1000);
					// }

					// /* TEST SECTION */
					// var spoilerHeight = $( '.content-spoiler' ).height();
					// var sectio5Height = $( '#section5' ).height();

					// console.log(spoilerHeight);
					// console.log(sectio5Height);

					// 	$( '#section5' ).fn.fullpage.reBuild();
					// 	$( '#section5 .fp-tableCell' ).fn.fullpage.reBuild();
					// 	$( '#section5 .fp-scrollable' ).fn.fullpage.reBuild();
					/* TEST SECTION */

					// $(this).toggleClass('active');
					// });
					// });
				}
				/* ----------------- end seo-text toggle --------------- */




				/* ----------------- start post page ----------------- */
				function scrollPostShare(){
					$(".p-single-meta-share a").on('click', function(event) {
						if (this.hash !== "") {
							event.preventDefault();
							var hash = this.hash;
							$('html, body').animate({
								scrollTop: $(hash).offset().top-150
							}, 800, function(){
								window.location.hash = hash;
							});
						}
					});
				}
				/* ----------------- end post page ----------------- */







				/* ----------------- start home page ----------------- */
				function ajaxLoadPost(){
					var par = $('.p-home-posts');
					var $container = $('.p-home-posts');
					var btn = $('.p-home-navigation a');
					$('.p-home').on('click', ".p-home-navigation a", function(event) {
						event.preventDefault();
						var data = {
							'action': 'post_load',
							'offset': $('.home-post').length,
							'catID': $("#catID").val(),
						};
						$.ajax({
							url: '/wp-admin/admin-ajax.php',
							data: data,
							type: 'POST',
							beforeSend: function (response) {
								par.block( {
									message: null,
									overlayCSS: {
										background: '#fff',
										opacity: 0.7
									}
								} );
								btn.block( {
									message: null,
									overlayCSS: {
										cursor: 'progress'
									}
								} );
							},
							complete: function (response) {
								par.unblock();
							},
							success: function(data) {
								if (data) {
									var $items = $(data),
									$container = $('.p-home-posts');
									$container.append($items);

									var data_arry = $(data).filter('div.home-post');
									if(data_arry.length<10){
										$('.p-home-navigation').hide();
									}
									btn.unblock();
								}
							}
						});
					});
				}
				/* ----------------- end home page ----------------- */


				/* ----------------- start product page ----------------- */
				function popupProductPage(){
					$('.send-request').on('click', function(event){	
						$(this).removeClass('wc-variation-selection-needed');

						/* Activate modal if attributes checked */
						if($('ul[data-attribute_name="attribute_pa_color"] .variable-item').hasClass('selected') && 
							$('ul[data-attribute_name="attribute_pa_size"] .variable-item').hasClass('selected') ) {
							var selectedColor = $('.color-variable-wrapper .selected').data('wvstooltip');
						var selectedSize = $('ul.button-variable-wrapper .selected').data('value');
						$('#selected-color').val(selectedColor);
						$('#selected-size').val(selectedSize);	
						$("#popup-send-request").modal({
							fadeDuration: 500,
						});
						$('body').addClass('has-open-modal');
					}
					/* Put error message if attributes checked */
					else{
						/* Activate error message block */
						$( '#btr-validation-error-messages' ).addClass( 'btr-validation-error-messages' );

						/* Get page's language */
						var lang = $('html').attr('lang');

						/* Get property name to choose */
						var prop_color='', prop_size='';
						if( !$('ul[data-attribute_name="attribute_pa_color"] li.variable-item.color-variable-item').hasClass('selected') ){ 
							prop_color = $('label[for="pa_color"]').text().toLowerCase(); 
						}
						if( !$('ul[data-attribute_name="attribute_pa_size"] li.variable-item.button-variable-item').hasClass('selected') ){ 
							prop_size = $('label[for="pa_size"]').text().toLowerCase(); 
						}

						var msg = {
							'en-US' : 'Please choose a ',
							'uk' : 'Оберіть будь-ласка ',
							'ru-RU' : 'Выберите пожалуйста ',
							'ro-RO' : 'Selectați o '
						}
						var unifier = {
							'en-US' : ' and ',
							'uk' : ' та ',
							'ru-RU' : ' и ',
							'ro-RO' : ' i '
						}
						if(prop_size == '' || prop_color == ''){
							unifier[lang] = '';
						}
						$( '.btr-validation-error-messages' ).html( '<p>' + msg[lang] + prop_size + unifier[lang] + prop_color + '</p>' );
					}
				});
					$('.modal-popup[href="#popup-become-partner"]').click(function(){
						$("#popup-become-partner").modal({
							fadeDuration: 500,
						});
						$('body').addClass('has-open-modal');
					});
					$('.modal-popup.size-guide-href').click(function(){
						$("#popup-size-guide").modal({
							fadeDuration: 500,
						});
						$('body').addClass('has-open-modal');
					});
					$('.main-gallery-slider a').click(function(e){
						e.preventDefault();
						$("#product-popup-slider").modal({
							fadeDuration: 500,
						});
						$('body').addClass('has-open-modal');
					});

					$('body').on('click', ".close-modal", function(event) {
						$('body').removeClass('has-open-modal');
					});
					$('html').on("click", '.jquery-modal.blocker.current', function () {
						var $box = $("#popup-become-partner, #popup-send-request, #popup-size-guide, #product-popup-slider");
						if ( $box.has(event.target).length == 0 && !$box.is(event.target)){
							$('body').removeClass('has-open-modal');
						}
					});
					$(document).keydown(function(e) {
						if( e.keyCode == 27 ) {
							$('body').removeClass('has-open-modal');
						}
					});
					$('.main-gallery-slider').slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						fade: true,
						adaptiveHeight: true,
						rows:0,
						accessibility:false,
						asNavFor: '.nav-gallery-slider'
					});
					$('.main-gallery-slider a').on('click', function(ev){
						var sl = $(this).parent().parent().attr("data-slick-index");
						// $('.product-popup-slider').slickGoTo(sl)
						$('.product-popup-slider').slick('slickGoTo', sl);
						// console.log(sl);
					  });

					  
					  $('.nav-gallery-slider a').on('click', function(ev){
					ev.preventDefault();
					  });
					$('.nav-gallery-slider').slick({
						slidesToShow: 4,
						slidesToScroll: 1,
						asNavFor: '.main-gallery-slider',
						dots: false,
						centerMode: false,
						arrows: true,
						infinite: false,
						focusOnSelect: true,
						centerPadding: 0
					});
					$('#pa_size').after('<i class="fa fa-chevron-down"></i>');
					$("#pa_size").on({
						"change": function() {
							$(this).blur();
						},
						"blur": function() {
							$('.woo-variation-items-wrapper .fa').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
						},
						"click": function() {
							$('.woo-variation-items-wrapper .fa').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
						}
					});
				}
				/* ================================= Change button color on validation =============================== */
				function changeBtnColorOnValidation(){
					if($('ul[data-attribute_name="attribute_pa_color"] .variable-item').hasClass('selected') && 
						$('ul[data-attribute_name="attribute_pa_size"] .variable-item').hasClass('selected') ) {
						$( 'button[type="submit"].single_add_to_cart_button' ).removeClass( 'locked' );
				}
			}
			/* ================================= Change button color on validation =============================== */
			function addToCartValidation(){
				$('button.single_add_to_cart_button').on('click', function(event){	
					$(this).removeClass('wc-variation-selection-needed');


					/* Activate modal if attributes checked */
					if($('ul[data-attribute_name="attribute_pa_color"] .variable-item').hasClass('selected') && 
						$('ul[data-attribute_name="attribute_pa_size"] .variable-item').hasClass('selected') ) {
						var selectedColor = $('.color-variable-wrapper .selected').data('wvstooltip');
					var selectedSize = $('ul.button-variable-wrapper .selected').data('value');
					$('#selected-color').val(selectedColor);
					$('#selected-size').val(selectedSize);
					var lockedBtn = $( 'button[type="submit"].single_add_to_cart_button' ).hasClass( 'locked' );
					lockedBtn.removeClass( 'locked' );
				}
				/* Put error message if attributes checked */
				else{
					/* Activate error message block */
					$( '#btr-validation-error-messages' ).addClass( 'btr-validation-error-messages' );

					/* Get page's language */
					var lang = $('html').attr('lang');

					/* Get property name to choose */
					var prop_color='', prop_size='';
					if( !$('ul[data-attribute_name="attribute_pa_color"] li.variable-item.color-variable-item').hasClass('selected') ){ 
						prop_color = $('label[for="pa_color"]').text().toLowerCase(); 
					}
					if( !$('ul[data-attribute_name="attribute_pa_size"] li.variable-item.button-variable-item').hasClass('selected') ){ 
						prop_size = $('label[for="pa_size"]').text().toLowerCase(); 
					}

					var msg = {
						'en-US' : 'Please choose a ',
						'uk' : 'Оберіть будь-ласка ',
						'ru-RU' : 'Выберите пожалуйста ',
						'ro-RO' : 'Selectați o '
					}
					var unifier = {
						'en-US' : ' and ',
						'uk' : ' та ',
						'ru-RU' : ' и ',
						'ro-RO' : ' i '
					}
					if(prop_size == '' || prop_color == ''){
						unifier[lang] = '';
					}
					$( '.btr-validation-error-messages' ).html( '<p>' + msg[lang] + prop_size + unifier[lang] + prop_color + '</p>' );
				}
			});
			}

			function popupVideoProduct(){
				$(".woocommerce-product-gallery__video").modalVideo({
					youtube:{
						controls:0,
						nocookie: true
					}
				});
			}

			function product_video(){
				$('.single-product').on('click', '.pswp__button', function(e) {
					if($('body').hasClass('single-product')){
						if ( !($(".pswp__item .pswp__vide").length > 0) ){
							var video = $('.hide-video .pswp__vide').clone();
							$('.single-product a[href^="hide-video"').parent().after(video);
						}
					}
				});
				$('.woocommerce-product-gallery__image').click(function(){
					setTimeout(function(){
						if ( !($(".pswp__item .pswp__vide").length > 0) ){
							var video = $('.hide-video .pswp__vide').clone();
							$('.single-product .pswp__error-msg').parent().after(video);
						}
					}, 3000);
				});
			}

			function productPopupSlider(){
				$(".product-popup-slider").slick({
					infinite: false,
					centerMode: true,
					centerPadding: '0',
					slidesToShow: 1,
					speed: 500,
					accessibility:false,
					responsive: [{
						breakpoint: 769,
						settings: {
							slidesToShow: 1
						}
					}]
				});
				// $('a[data-slide=1]').addClass('active');
				// $('a[data-slide]').click(function(e) {
				// 	e.preventDefault();
				// 	var slideno = $(this).data('slide');
				// 	console.log(slideno);
				// 	// $('.product-popup-slider').slick('slickGoTo', slideno-1);
				// });
				// $('.product-popup-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
				// 	var carent_slide = nextSlide;
				// 	// console.log(nextSlide+1);
				// 	$('.woocommerce-product-gallery__wrapper a').removeClass('active');
				// 	$('.woocommerce-product-gallery__wrapper a[data-slide='+carent_slide+']').addClass('active');
				// });

			}

			/* ----------------- end product page ----------------- */




			/* ----------------- start cart-checkout page ----------------- */
			function incDecQuantity(){
				$('.product-quantity button.dec').each(function(){
					var _t = $(this).parents('.product-quantity'),
					btn = $(this),
					inp = _t.find('input'),
					currVal = parseFloat(inp.val());
					if(currVal == 1){
						$(this).addClass('disable_btn');
						return 0;
					}
				});
				var timer;
				$(".woocommerce-cart-form").on("click", ".product-quantity button", function() {
					$('button.dec').removeClass('disable_btn');
					var _t = $(this).parents('.product-quantity'),
					btn = $(this),
					inp = _t.find('input'),
					currVal = parseFloat(inp.val()),
					max = parseFloat(inp.attr('max'));
					if(btn.hasClass('dec') && (currVal == 1)){
						console.log(currVal);
						$(this).addClass('disable_btn');
						return 0;
					}
					if (max == '' || isNaN(max)) { max = 100; }
					if(btn.hasClass('dec') && (currVal > 1)){
						inp.val(currVal-1);
					} else if (btn.hasClass('inc')){
						inp.val(currVal+1);
					}
					newVal = parseFloat(inp.val());
					clearTimeout(timer);
					var ms = 400; 
					var val = $(this).val();
					timer = setTimeout(function() {
						ajaxQtyMiniCart(inp);
					}, ms);
				})

				$('.woocommerce-cart-form').on('input','.product-quantity input',function(){
					var _t = $(this),
					currVal = parseFloat(_t.val()),
					max = parseFloat(_t.attr('max'));
					if (max == '' || isNaN(max)) { max = 100; }
					if(currVal<0 || isNaN(currVal)){
						_t.val('1');
					} else if (currVal >= max){
						_t.val(max);
					} else {
						_t.val(Math.round(currVal));
					}
					newVal = parseFloat(_t.val());
					clearTimeout(timer);
					var ms = 400; 
					var val = $(this).val();
					timer = setTimeout(function() {
						ajaxQtyMiniCart(_t);
					}, ms);
				});
			}

			function cartComment(){
				$('.comment-group textarea').each(function(){
					var comment = $(this).val();
					if(comment==''){
						// $(this).parents('.comment-group').addClass('add-comment');
					} else{
						$(this).parents('.comment-group').removeClass('add-comment');
						$(this).parents('.comment-group').addClass('edit-comment');
					}
				});
				$(document).on('click', ".comment-group-top-edit",function () {
					$(this).parents('.comment-group').addClass('save-comment');
					$(this).parents('.comment-group').removeClass('edit-comment');
				});
				$(document).on('click', ".comment-group-top-add",function () {
					$(this).parents('.comment-group').addClass('save-comment');
					$(this).parents('.comment-group').removeClass('add-comment');
				});
				$(document).on('click', ".order-comment-add",function () {
					$('.order-comment').addClass('edite-comment');
					$('#order_comments_field').addClass('edite-comment');
				});
			}

			function ajaxQtyMiniCart(inp){
				var item_hash = inp.attr( 'name' ).replace(/cart\[([\w]+)\]\[qty\]/g, "$1"),
				item_quantity = inp.val(),
				currentVal = parseFloat(item_quantity.replace(',', '.')),
				par = inp.parents('.cart-page-content');
				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					data: {
						action: 'qty_cart',
						hash: item_hash,
						quantity: currentVal
					},
					beforeSend: function (response) {
						par.block( {
							message: null,
							overlayCSS: {
								background: '#fff',
								opacity: 0.6
							}
						} );
					},
					complete: function (response) {
						par.unblock();
					},
					success: function (response) {
						if (response.error & response.product_url) {
							window.location = response.product_url;
							return;
						} else {
							$(document.body).trigger('added_to_cart',[response.fragments, response.cart_hash]);
						}
					}
				});  
			}
			/* ----------------- end cart-checkout page ----------------- */








			/* ----------------- start archive product ----------------- */
			function archiveProductCount() {
				var resultCount = $( ".woocommerce-result-count" ).text();
				resultCount = '<span class="woocommerce-breadcrumb-count">' + resultCount + '</span>'
				$( ".woocommerce-breadcrumb" ).append(resultCount);
			}

			function archiveProductSliders(){
				var swiper = new Swiper('.product-wr-container', {
					loop: true,
					effect: 'fade',
					navigation: {
						nextEl: '.product-wr-button-next',
						prevEl: '.product-wr-button-prev',
					},
				});
			}

			function archiveProductPagination(){
				$('.prev.page-numbers').parent().addClass('prev-li');
				$('.next.page-numbers').parent().addClass('next-li');
			}

			function archiveProductFilter(){
				$('.archive-product-top-filter-toggle').on('click', function(){
					$('.archive-product-top').toggleClass('filter-open');
					$('.prdctfltr_woocommerce_filter ').click();
				})
			}

			function archiveProductCountPagination(){
				var resultCount = $( ".woocommerce-result-count" ).text();
				$( ".woocommerce-breadcrumb-count" ).text(resultCount);
			}
			/* ----------------- end archive product ----------------- */

			/* ----------------- start popup block ----------------- */

			function lockPopup() {
				$('.open-popup-video').on('click', function(){	
					if($('.modal-video').length) {
						$( '.modal-video-body' ).css( 'pointer-events', 'none' );
						$('#masthead').css('opacity', '0');	
						$('.modal-video').on('click', function(){
							$('#masthead').css('opacity', '1');
						});
						if (window.screen.width <= 1198) {
							$('.modal-video-inner').on('touchend click', function() {
								$('#masthead').css('opacity', '1');
								$('.modal-video').remove();	
								$('.page-template').removeClass('modal-open').unbind('scroll');
							});
						}
					} 
				});
			}

			/* ----------------- send popup lock ----------------- */

			/* ----------------- end button lock ----------------- */

			/* ----------------- start make reqired selectors: color & size ----------------- */
			function makeSelectRequired(){
				jQuery(document).ready(function($) {
					$('select#pa_color').prop('required', true);
					$('select#pa_size').prop( 'required', true);
				});
			}
			/* ----------------- end make reqired selectors: color & size ----------------- */

			/* ================================= Test block ================================ */
			function preventClicksThroughOverlay( event ) {
				$( '.modal-video-body' ).click( function( event ){
					alert("The \".modal-video-body\" element was clicked.");
				} );
				// event.stopPropagation();
				// modal-video-inner
			}

			/* ================================== Product slider: make  ================================== */

			function notClickableProductImage(){
				$( '.slick-current a' ).not( ['data-slide'] ).attr('data-slide', '1').click(function(e){
					e.preventDefault();
					$( '#product-popup-slider .slick-track' ).css({
						width: '23860px',
						transform: 'translate3d(-2386px, 0px, 0px)'
					});
					$( '#product-popup-slider .slick-track .slick-slide.slick-cloned' ).css( 'width', '1193px' );
					$( '#product-popup-slider .slick-slide.slick-current.slick-active.slick-center' ).css( 'width', '1193px' );
				})
			};
			/* ================================== ! Product slider: make Product Image not clickable ================================== */

			/* ================================== remove Input Auto Complete ================================== */

			$('document').ready(function(){
				removeInputAutoComplete ();
				$('input[name="your-email"]').click(function(){ removeInputAutoComplete (); });
			});
			function removeInputAutoComplete () { $('input[name="your-email"]').attr('autocomplete', 'off'); }

			/* ================================== end remove Input Auto Complete ================================== */

jQuery(function ($) {
	$(document).ready(function(){

		/* open store info popup by click*/
		$('.open-t-store-info').click(function(){
			$("#t-store-info-shop-popup").modal({
				fadeDuration: 500,
			});
		});

		/* get curent location by click button */
		$('.get-cur-loc').on('click', function(){
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(success, error);
			} else {
				alert('Geolocation is not supported by this browser');
			}
			function success(position){
				pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};

				/*add class active to choose-radius element after first render map to show radius select during render map*/
				user_loc = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
				renderMapByUserData();
			}

			function error(){
				alert('Please, turn on geolocation for this browser in "Settings"');
			}
		});


		/* countries */
		$('#category').on('change',function(){
			$('.t-store-filter').addClass('active');
			$('.marker').removeClass('showing');
			var curr = $(this).val();
			$('.city').removeClass('active');
			$('.open-t-store-info-shop').hide();
			$('.marker').hide();
			if (curr=='marker'){
				$('.marker').hide();
			} else {
				$('.marker.'+curr).show();
				$('.marker.'+curr).addClass('showing');
			}

			$('#'+curr).addClass('active');
			$('.t-store-info-shop').each(function(){
				render_map( $(this), curr );
			});

			if($('.marker').hasClass('showing')){
				if($('#t-store-info-shop-popup').length){
					$("#t-store-info-shop-popup").modal({
						fadeDuration: 500,
					});
				}
				$('.open-t-store-info-shop').show();
			}
		});

		/* cities */
		$('.city').on('change',function(){
			$('.t-store-filter').addClass('active');
			$('.city').removeClass('active');
			$(this).addClass('active');
			$('.marker').removeClass('showing');
			$('.open-t-store-info-shop').hide();
			var curr = $(this).val();
			$('.marker').hide();
			$('.marker.'+curr).show();
			$('.marker.'+curr).addClass('showing');
			$('.t-store-info-shop').each(function(){
				render_map( $(this), curr );
			})
			
			if($('.marker').hasClass('showing')){
				if($('#t-store-info-shop-popup').length){
					$("#t-store-info-shop-popup").modal({
						fadeDuration: 500,
					});
				}
				$('.open-t-store-info-shop').show();
			}
		});


		/* map */
		var map, infowindow, lat, lon, pos, user_loc, radius, currentRadius;
		var nearestMarker;
		var partPath = $('.site-header').attr('part-path');
		var markerIcon = partPath + '/assets/img/b-location.png';
		var markerIcon1 = partPath + '/assets/img/b-location';
		/* render map on ready document */
		var curr = '';
		$('.t-store-info-shop').each(function(){
			render_map( $(this), curr );
		});


		function render_map( $el, curr ) {
			nearestMarker = 99999999999999;
			if (curr.length){
				var $markers = $el.find('.'+curr);
			}else {
				var $markers = $el.find('.marker');
			}
			var args = {
				zoom		: 10,
				center	: new google.maps.LatLng(51.356628, -32.308081),
				/* mapTypeId    : google.maps.MapTypeId.ROADMAP, */
				/* disableDefaultUI: true, */
				/* scrollwheel:false, */
				/* draggable:false */
				gestureHandling: 'cooperative',
				mapTypeControl: false,
				/* streetViewControl: false, */
				/* scaleControl: false, */
				/* zoomControl: false, */
			};
			map = new google.maps.Map( document.getElementById("newMap"), args);
			map.markers = [];
			$markers.each(function(){
				add_marker( $(this), map  );
			});
			if (navigator.geolocation && !$('.t-store-filter').hasClass('active')) {
				navigator.geolocation.getCurrentPosition(function(position) {
					pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					map.setCenter(pos);
					map.setZoom(8); 
				});
			}
			if(map.markers.length<1){
				var bounds = new google.maps.LatLngBounds();
				$.each( map.markers, function( i, marker ){
					var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
					bounds.extend( latlng );
				});
				map.setCenter( new google.maps.LatLng(49.911113, 8.990636) );
				map.setZoom( 3 );
			}
			else{
				center_map( map );
			}
			/* Marker Clusterer */
			var options = {
				imagePath: markerIcon1,
			};
			var markerCluster = new MarkerClusterer(map, map.markers, options);

			/* if has markers in location radius show lenght to closest marker(shop) */
			if(nearestMarker < 99999999999999){
				$('.to-shop').addClass('active').find('span').text(Math.round((nearestMarker/1000) * 100)/100);
			}
		}

		function add_marker( $marker, map ) {
			var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
			if(user_loc){
				var distance = google.maps.geometry.spherical.computeDistanceBetween(latlng, user_loc);
			} else{
				var lviv = new google.maps.LatLng(51.356628, -32.308081);
				var distance = google.maps.geometry.spherical.computeDistanceBetween(latlng, lviv);
			}

			if(radius){
				currentRadius = radius;
			} else {
				currentRadius = 100000;
			}

			/* якщо відстань до маркера менша за радіус - показуємо мітку */
			if(distance<currentRadius){
				if(distance<nearestMarker){
					nearestMarker = distance;
				}
				/* create marker */
				var marker = new google.maps.Marker({
					position    : latlng,
					map            : map,
					icon: markerIcon
				});
				/* add to array */
				map.markers.push( marker );
				/* if marker contains HTML, add it to an infoWindow */
				if( $marker.html() ){
					/* show info window when marker is clicked */
					google.maps.event.addListener(marker, 'click', function() {
						/* close previus infowindow */
						if(infowindow){
							infowindow.close();
						}
						/* create info window */
						infowindow = new google.maps.InfoWindow({
							content        : $marker.html()
						})
						/* show infowindow */
						infowindow.open( map, marker );

					});
				}
			}
			else{
				if(!user_loc){
					/* create marker */
					var marker = new google.maps.Marker({
						position    : latlng,
						map            : map,
						icon: markerIcon
					});
					/* add to array */
					map.markers.push( marker );
					/* if marker contains HTML, add it to an infoWindow */
					if( $marker.html() ){
						/* show info window when marker is clicked */
						google.maps.event.addListener(marker, 'click', function() {
							/* close previus infowindow */
							if(infowindow){
								infowindow.close();
							}
							/* create info window */
							infowindow = new google.maps.InfoWindow({
								content        : $marker.html()
							})
							/* show infowindow */
							infowindow.open( map, marker );
						});
					}
				}
			}
		}

		function center_map( map ) {
			var bounds = new google.maps.LatLngBounds();
			/* loop through all markers and create bounds */
			$.each( map.markers, function( i, marker ){
				var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
				bounds.extend( latlng );
			});
			/* only 1 marker? */
			if( map.markers.length == 1 ){
				/* set center of map */
				map.setCenter( bounds.getCenter() );
				map.setZoom( 15 );
			}  else if ( map.markers.length < 50 ){
				/* fit to bounds */
				map.fitBounds( bounds );
			}
			else{
				/* show all markers */
				map.setCenter( new google.maps.LatLng(49.911113, 8.990636) );
				map.setZoom( 3 );
			}
		}

		function renderMapByUserData(){
			var curr = '';
			$('#newMap').show();
			$('html, body').animate({
				scrollTop: $("#newMap").offset().top
			}, 1000);

			/*add class active to choose-radius element after first render map to show radius select during render map*/
			$('.acf-map').each(function(){
				render_map( $(this), curr );
			});
		}

	});
})